/* eslint-disable max-len */
import {HttpClientModule} from "@angular/common/http";
import {APP_INITIALIZER, ErrorHandler, NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AgGridModule} from "ag-grid-angular";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {ModalModule} from "ngx-bootstrap/modal";
import {PaginationModule} from "ngx-bootstrap/pagination";
import {TimepickerModule} from "ngx-bootstrap/timepicker";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {ClipboardModule} from "ngx-clipboard";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {ActionLogComponent} from "./features/action-log/action-log.component";
import {ActionLogService} from "./features/action-log/action-log.service";
import {AnalysisService} from "./features/analize/analysis.service";
import {BloodPressureChartComponent} from "./features/analize/blood-pressure/blood-pressure-chart/blood-pressure-chart.component";
import {BloodPressureTableComponent} from "./features/analize/blood-pressure/blood-pressure-table/blood-pressure-table.component";
import {BloodPressureService} from "./features/analize/blood-pressure/bloodPressure.service";
import {BpAnalysisComponent} from "./features/analize/blood-pressure/bp-analysis.component";
import {BpFindingsValidator} from "./features/analize/blood-pressure/bp-findings-validator";
import {BloodPressureReportGenerator} from "./features/analize/bp-report/blood-pressure-report-generator.service";
import {EcgReportGenerator} from "./features/analize/ecg-report/ecg-report-generator.service";
import {EcgAnalysisComponent} from "./features/analize/ecg/ecg-analysis.component";
import {EcgFindingsValidator} from "./features/analize/ecg/ecg-findings-validator";
import {FinishAnalysisService} from "./features/analize/finish-analysis.service";
import {MeasurementIdProvider} from "./features/analize/measurement-id-provider";
import {AuthGuard} from "./features/authentication/auth-guard.service";
import {AuthenticatedUserStore} from "./features/authentication/authenticated-user-store.service";
import {AuthenticationService} from "./features/authentication/authentication.service";
import {HomeGuard} from "./features/authentication/home-guard.service";
import {BackgroundInformationComponent} from "./features/background-information/background-information.component";
import {ChangePasswordComponent} from "./features/change-password/change-password.component";
import {ChangePasswordService} from "./features/change-password/change-password.service";
import {CloseAnalysisService} from "./features/list/close-analysis.service";
import {DoctorAssignmentService} from "./features/list/doctor-assignment.service";
import {DoctorSelectorComponent} from "./features/list/doctor-selector/doctor-selector.component";
import {DoctorSelectorService} from "./features/list/doctor-selector/doctor-selector.service";
import {DoctorsService} from "./features/list/doctor-selector/doctors.service";
import {ListItemTextBuilder} from "./features/list/list-item-text-builder.service";
import {ListComponent} from "./features/list/list.component";
import {ListService} from "./features/list/list.service";
import {RejectStudyService} from "./features/list/reject/reject-study.service";
import {ReportFilenameFactory} from "./features/list/report/download/report-filename-factory.service";
import {ReportUploadComponent} from "./features/list/report/report-upload.component";
import {ReportService} from "./features/list/report/report.service";
import {ReturnStudyComponent} from "./features/list/return-study/return-study.component";
import {ReturnService} from "./features/list/return-study/return.service";
import {MeasurementDetailsButtonComponent} from "./features/list/measurement-details/measurement-details-button.component";
import {MeasurementDetailsComponent} from "./features/list/measurement-details/measurement-details.component";
import {LoginValidator} from "./features/login/login-validator";
import {LoginComponent} from "./features/login/login.component";
import {LoginService} from "./features/login/login.service";
import {NewUsersComponent} from "./features/new-users/new-users.component";
import {NewUsersService} from "./features/new-users/new-users.service";
import {BloodPressureUploadFormComponent} from "./features/upload/blood-pressure/blood-pressure-upload-form.component";
import {EcgAtrialFibrillationUploadFormComponent,} from "./features/upload/ecg-atrial-fibrillation/ecg-atrial-fibrillation-upload-form.component";
import {HolterEcgUploadFormComponent} from "./features/upload/holter-ecg/holter-ecg-upload-form.component";
import {SleepUploadFormComponent} from "./features/upload/sleep/sleep-upload-form.component";
import {EcgSymptomComponent} from "./features/upload/symptom-holter/ecg-symptom.component";
import {SymptomHolterUploadFormComponent} from "./features/upload/symptom-holter/symptom-holter-upload-form.component";
import {UploadFileValidator} from "./features/upload/upload-file/upload-file-validator";
import {UploadMeasurementFileComponent} from "./features/upload/upload-file/upload-measurement-file.component";
import {UploadComponent} from "./features/upload/upload.component";
import {UploadService} from "./features/upload/upload.service";
import {UserListComponent} from "./features/user-list/user-list.component";
import {UserListService} from "./features/user-list/user-list.service";
import {UserMenuComponent} from "./features/user-menu/user-menu.component";
import {FooterComponent} from "./footer.component";
import {I18nService} from "./i18n/i18n.service";
import {LanguageProvider, UserLanguageProvider} from "./i18n/language-provider";
import {LocalizePipe} from "./i18n/localize-pipe";
import {DateWithCopyRendererComponent} from "./tools/ag-grid-custom-components/date-with-copy-renderer/date-with-copy-renderer.component";
import {MapCellRendererComponent} from "./tools/ag-grid-custom-components/map-cell-renderer/map-cell-renderer.component";
import {ApiClient, productionBaseUrlProvider} from "./tools/api-client";
import {ActionButtonComponent} from "./tools/button/action-button.component";
import {DateTimePickerComponent} from "./tools/date-time-picker/date-time-picker.component";
import {AppLocalDateTimePipe, AppUtcDateTimePipe} from "./tools/date-time.pipe";
import {ErrorResponseHandler} from "./tools/error-response-handler";
import {ErrorIdProvider} from "./tools/errors/error-id-provider.service";
import {ErrorComponent} from "./tools/errors/error.component";
import {FormFieldDisplayComponent} from "./tools/form/form-field-display.component";
import {FormFieldWrapperComponent} from "./tools/form/form-field-wrapper.component";
import {FormFieldComponent} from "./tools/form/form-field.component";
import {FormHeaderComponent} from "./tools/form/form-header.component";
import {LoadingIndicatorComponent} from "./tools/loading-indicator/loading-indicator.component";
import {LocalStorageService} from "./tools/local-storage.service";
import {RouteNavigator} from "./tools/navigation/route-navigator.service";
import {Notifications} from "./tools/notifications/notifications";
import {NotificationsComponent} from "./tools/notifications/notifications.component";
import {Scheduler} from "./tools/notifications/scheduler";
import {BloodPressureFindingsComponent} from "./features/analize/blood-pressure/blood-pressure-findings/blood-pressure-findings.component";
import {TimeFilterComponent} from "./features/user-list/time-filter/time-filter.component";
import {DateTimeSerializationService} from "./tools/formatting/date-time-serialization.service";
import {ClockService} from "./tools/time/clock.service";
import {SelectComponent} from "./tools/select/select.component";
import {OtpComponent} from "./features/login/otp/otp.component";
import {UserCredentialsComponent} from "./features/login/user-credentials/user-credentials.component";
import {ChangeLanguageComponent} from "./features/change-language/change-language.component";
import {ChangeLanguageService} from "./features/change-language/change-language.service";
import {InitiateMeasurementComponent} from "./features/initiate-measurement/initiate-measurement.component";
import {InitiateMeasurementService} from "./features/initiate-measurement/initiate-measurement.service";
import {OperatorHospitalsService} from "./features/initiate-measurement/operator-hospitals.service";
import {OrderMeasurementService} from "./features/initiate-measurement/order-measurement.service";
import {PrintOrderFormComponent} from "./features/print-order-form/print-order-form.component";
import {PrintOrderService} from "./features/print-order-form/print-order.service";
import {OrderPdfGeneratorService} from "./features/print-order-form/order-pdf-generator.service";
import {HospitalsComponent} from "./features/hospitals/hospitals.component";
import {HospitalsService} from "./features/hospitals/hospitals.service";
import {EditHospitalComponent} from "./features/hospitals/edit-hospital/edit-hospital.component";
import {EditHospitalService} from "./features/hospitals/edit-hospital/edit-hospital.service";
import {HospitalIdProvider} from "./features/hospitals/edit-hospital/hospital-id-provider";
import {PrintFormComponent} from "./features/print-form/print-form.component";
import {PdfDownloaderService} from "./features/print-form/pdf-downloader.service";
import {PdfFillerService} from "./features/print-form/pdf-filler.service";
import {PrintFormService} from "./features/print-form/print-form.service";
import {EFormLandingPageComponent} from "./features/e-forms/landing-page/e-form-landing-page.component";
import {EFormService} from "./features/e-forms/e-form.service";
import {EFormFormComponent} from "./features/e-forms/form/e-form-form.component";
import {environment} from "../environments/environment";
import {EformRoutingModule} from "./eform-routing.module";
import {EFormIdProvider} from "./features/e-forms/e-form-id-provider.service";
import {RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings,} from "ng-recaptcha";
import {RecaptchaService} from "./features/e-forms/landing-page/recaptcha.service";
import {HospitalMeasurementTypesService} from "./features/hospitals/edit-hospital/hospital-measurement-types.service";
import {CommentsComponent} from "./features/comments/comments.component";
import {CommentsService} from "./features/comments/comments.service";
import {DownloadExternalFindingsService} from "./features/analize/ecg/download-external-findings.service";
import {DownloaderService} from "./tools/downloader.service";
import {EditMeasurementComponent} from "./features/list/edit-measurement/edit-measurement/edit-measurement.component";
import {UploadFormSelectorComponent} from "./features/upload/form-selector/upload-form-selector.component";
import {EditMeasurementDataService} from "./features/list/edit-measurement/edit-measurement/edit-measurement-data.service";
import {AssignTechnicianService} from "./features/list/assign-technician/assign-technician.service";
import {CharacterCounterComponent} from "./tools/form/character-counter/character-counter/character-counter.component";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {ReleaseTogglesService} from "./tools/release-toggles/release-toggles.service";
import {ReleaseToggle, ReleaseToggleState} from "./tools/release-toggles/release-toggle-state";
import {PatientStatusCommentsGeneratorComponent} from "./features/analize/ecg/generators/patient-status-comments-generator/patient-status-comments-generator.component";
import {ChartService} from "./features/analize/blood-pressure/blood-pressure-chart/chart.service";
import {SelectionGeneratorComponent} from "./features/analize/ecg/generators/widgets/selection-generator/selection-generator.component";
import {ValueGeneratorComponent} from "./features/analize/ecg/generators/widgets/value-generator/value-generator.component";
import {FindingsGeneratorComponent} from "./features/analize/ecg/generators/findings-generator/findings-generator.component";
import {AutosizeModule} from "ngx-autosize";
import {DiagnosisGeneratorComponent} from "./features/analize/ecg/generators/diagnosis-generator/diagnosis-generator.component";
import {GeneratorTextFieldComponent} from "./features/analize/ecg/generators/widgets/generator-text-field/generator-text-field.component";
import {DeleteMeasurementService} from "./features/list/delete-measurement/delete-measurement.service";
import {ReloadApp} from "./reload-app";
import {VersionMismatch} from "./version-mismatch";
import {RouteListener} from "./route-listener";
import {ListItemComponent} from "./features/list/list-item/list-item.component";
import {RestoreMeasurementService} from "./features/list/restore-measurement/restore-measurement.service";
import {HolterTypeEditorComponent} from "./features/list/edit-holter-type/holter-type-editor.component";
import {MeasurementDetailsService} from "./features/list/measurement-details/measurement-details.service";
import {EditHolterTypeService} from "./features/list/edit-holter-type/edit-holter-type.service";
import {ListItemActionButtonsComponent} from "./features/list/list-item/list-item-action-buttons/list-item-action-buttons.component";
import {ReassignDoctorService} from "./features/list/reassign-doctor/reassign-doctor.service";
import {ReassignDoctorButtonComponent} from "./features/list/reassign-doctor/reassign-doctor-button/reassign-doctor-button.component";
import {ChainSectionComponent} from "./features/hospitals/edit-hospital/chain-section/chain-section.component";
import { NurseSettingsSectionComponent } from "./features/hospitals/edit-hospital/nurse-settings-section/nurse-settings-section.component";
import {NurseSettingsSectionState} from "./features/hospitals/edit-hospital/nurse-settings-section/nurse-settings-section.state";
import { DownloadReportComponent } from "./features/list/list-item/download-report/download-report.component";
import { AllowedTypesSectionComponent } from "./features/hospitals/edit-hospital/allowed-types-section/allowed-types-section.component";
import {AllowedTypesSectionState} from "./features/hospitals/edit-hospital/allowed-types-section/allowed-types-section.state";
import {EFormHeaderComponent} from "./features/e-forms/form/header/e-form-header.component";
import {EFormMailingInfoComponent} from "./features/e-forms/form/mailing-info/e-form-mailing-info.component";
import {EFormMailingInfoService} from "./features/e-forms/form/mailing-info/e-form-mailing-info.service";

@NgModule({
  declarations: [
    BloodPressureTableComponent,
    AppComponent,
    ListComponent,
    UploadComponent,
    NotificationsComponent,
    LocalizePipe,
    FormFieldWrapperComponent,
    FormFieldComponent,
    FormHeaderComponent,
    FormFieldComponent,
    ActionButtonComponent,
    LoadingIndicatorComponent,
    DoctorSelectorComponent,
    LoginComponent,
    OtpComponent,
    UserCredentialsComponent,
    ReportUploadComponent,
    FooterComponent,
    SleepUploadFormComponent,
    HolterEcgUploadFormComponent,
    SymptomHolterUploadFormComponent,
    EcgAtrialFibrillationUploadFormComponent,
    BloodPressureUploadFormComponent,
    UploadMeasurementFileComponent,
    EcgSymptomComponent,
    DateTimePickerComponent,
    EcgAnalysisComponent,
    FormFieldDisplayComponent,
    AppLocalDateTimePipe,
    AppUtcDateTimePipe,
    ReturnStudyComponent,
    MeasurementDetailsButtonComponent,
    MeasurementDetailsComponent,
    ErrorComponent,
    ActionLogComponent,
    MapCellRendererComponent,
    DateWithCopyRendererComponent,
    BpAnalysisComponent,
    NewUsersComponent,
    UserMenuComponent,
    ChangePasswordComponent,
    ChangeLanguageComponent,
    UserListComponent,
    BackgroundInformationComponent,
    BloodPressureChartComponent,
    BloodPressureFindingsComponent,
    TimeFilterComponent,
    SelectComponent,
    InitiateMeasurementComponent,
    PrintOrderFormComponent,
    HospitalsComponent,
    EditHospitalComponent,
    PrintFormComponent,
    EFormLandingPageComponent,
    EFormFormComponent,
    CommentsComponent,
    EditMeasurementComponent,
    UploadFormSelectorComponent,
    CharacterCounterComponent,
    PatientStatusCommentsGeneratorComponent,
    SelectionGeneratorComponent,
    ValueGeneratorComponent,
    FindingsGeneratorComponent,
    DiagnosisGeneratorComponent,
    GeneratorTextFieldComponent,
    ListItemComponent,
    HolterTypeEditorComponent,
    ListItemActionButtonsComponent,
    ReassignDoctorButtonComponent,
    ChainSectionComponent,
    NurseSettingsSectionComponent,
    DownloadReportComponent,
    AllowedTypesSectionComponent,
    EFormHeaderComponent,
    EFormMailingInfoComponent
  ],
  imports: [
    AgGridModule.withComponents([]),
    PaginationModule.forRoot(),
    BrowserModule,
    environment.eforms ?
      EformRoutingModule :
      AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    TimepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    BsDropdownModule.forRoot(),
    RecaptchaModule,
    AutosizeModule
  ],
  providers: [
    BloodPressureService,
    UserListService,
    NewUsersService,
    ChangePasswordService,
    ListService,
    ActionLogService,
    UploadService,
    Notifications,
    RouteNavigator,
    Scheduler,
    {
      provide: LanguageProvider,
      useClass: UserLanguageProvider,
    },
    I18nService,
    DoctorSelectorService,
    DoctorsService,
    DoctorAssignmentService,
    LoginService,
    LoginValidator,
    ListItemTextBuilder,
    ClipboardModule,
    ApiClient,
    LoginService,
    LocalStorageService,
    AuthenticationService,
    AuthenticatedUserStore,
    AuthGuard,
    HomeGuard,
    ReportService,
    HomeGuard,
    ErrorResponseHandler,
    ErrorHandler,
    ReturnService,
    UploadFileValidator,
    MeasurementIdProvider,
    AnalysisService,
    EcgReportGenerator,
    BloodPressureReportGenerator,
    FinishAnalysisService,
    EcgFindingsValidator,
    BpFindingsValidator,
    CloseAnalysisService,
    ReportFilenameFactory,
    ErrorIdProvider,
    RejectStudyService,
    DateTimeSerializationService,
    ClockService,
    InitiateMeasurementService,
    ChangeLanguageService,
    OperatorHospitalsService,
    OrderMeasurementService,
    PrintOrderService,
    OrderPdfGeneratorService,
    HospitalsService,
    EditHospitalService,
    HospitalIdProvider,
    PdfDownloaderService,
    PdfFillerService,
    PrintFormService,
    productionBaseUrlProvider(),
    EFormService,
    EFormIdProvider,
    DeleteMeasurementService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {siteKey: environment.recaptchaSiteKey} as RecaptchaSettings
    },
    RecaptchaService,
    HospitalMeasurementTypesService,
    CommentsService,
    DownloadExternalFindingsService,
    DownloaderService,
    EditMeasurementDataService,
    AssignTechnicianService,
    ReleaseTogglesService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadReleaseToggles,
      deps: [ReleaseTogglesService, ApiClient],
      multi: true
    },
    ChartService,
    ReloadApp,
    VersionMismatch,
    RouteListener,
    RestoreMeasurementService,
    MeasurementDetailsService,
    EditHolterTypeService,
    ReassignDoctorService,
    NurseSettingsSectionState,
    AllowedTypesSectionState,
    EFormMailingInfoService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

function loadReleaseToggles(releaseToggleService: ReleaseTogglesService): () => Observable<any> {
  return () => releaseToggleService
    .getAllToggles()
    .pipe(
      tap<ReleaseToggle[]>(releaseToggles =>
        ReleaseToggleState.getInstance().setToggles(releaseToggles)
      )
    );
}
