export interface LocalizationStrings {
  readonly navigationLogoDescription: string;

  readonly footerCustomerSupportLabel: string;
  readonly footerCustomerSupportPhoneLabel: string;

  readonly loadingLabel: string;

  readonly navigationList: string;
  readonly navigationUpload: string;
  readonly navigationActionsLog: string;
  readonly navigationUserList: string;
  readonly navigationAddUsers: string;
  readonly navigationLogout: string;
  readonly navigationBackgroundInformation: string;
  readonly navigationInitiateMeasurement: string;

  readonly authGuardForbidden: string;
  readonly authTokenExpired: string;
  readonly authAccessDenied: string;
  readonly authAuthenticateFailed: string;

  readonly cellRendererCopyToClipboard: string;

  readonly loginFailedInvalidCredentials: string;
  readonly loginFailed: string;
  readonly loginFormLabelUsername: string;
  readonly loginFormLabelPassword: string;
  readonly loginFormUsernameInvalidLabel: string;
  readonly loginFormPasswordInvalidLabel: string;
  readonly loginFormButtonLoginLabel: string;
  readonly loginFormButtonLoginInProgressLabel: string;

  readonly loginFormLabelOtp: string;
  readonly loginFormOtpInvalidLabel: string;
  readonly loginFormButtonOtpLoginLabel: string;
  readonly loginFormButtonOtpLoginInProgressLabel: string;
  readonly loginFormOtpTitleLabel: string;
  readonly loginFormInvalidOtp: string;

  readonly changePasswordOldPasswordLabel: string;
  readonly changePasswordNewPasswordLabel: string;
  readonly changePasswordRetypePasswordLabel: string;
  readonly changePasswordStrongPasswordMessage: string;
  readonly changePasswordLabel: string;
  readonly changePasswordSuccessfulMessage: string;
  readonly changePasswordFailedMessage: string;

  readonly modalPromptAreYouSure: string;
  readonly modalResponseYes: string;
  readonly modalResponseNo: string;

  readonly userListGetListFailed: string;
  readonly userListButtonLabelResetPassword: string;
  readonly userListButtonLabelResetPasswordInProgress: string;
  readonly userListResetPasswordFailed: string;
  readonly userListResetPasswordSucceeded: string;
  readonly userListButtonLabelActivateUser: string;
  readonly userListButtonLabelActivateUserInProgress: string;
  readonly userListActivateUserFailed: string;
  readonly userListActivateUserSucceeded: string;
  readonly userListButtonLabelDeactivateUser: string;
  readonly userListButtonLabelDeactivateUserInProgress: string;
  readonly userListDeactivateUserFailed: string;
  readonly userListDeactivateUserSucceeded: string;

  readonly backgroundInformationTitle: string;

  readonly measurementsGridColumnLabelCode: string;
  readonly measurementsGridColumnLabelUniqueId: string;
  readonly measurementsGridColumnLabelStatus: string;
  readonly measurementsGridColumnLabelUploadTime: string;
  readonly measurementsGridColumnLabelInitiateTime: string;
  readonly measurementsGridColumnLabelNurse: string;
  readonly measurementsGridColumnLabelDeliveryTime: string;
  readonly measurementsGridColumnLabelType: string;
  readonly measurementsGridColumnLabelHospitalName: string;
  readonly measurementsGridColumnLabelDoctor: string;
  readonly measurementsGridColumnLabelAction: string;

  readonly measurementsGridButtonLabelCopy: string;
  readonly measurementsGridButtonLabelAssign: string;
  readonly measurementsGridButtonLabelUploadReport: string;
  readonly measurementsGridButtonLabelDownloadReport: string;
  readonly measurementsGridButtonLabelOpenAnalysis: string;
  readonly measurementsGridButtonLabelCloseAnalysis: string;
  readonly measurementsGridButtonLabelCloseAnalysisInProgress: string;
  readonly measurementsGridButtonLabelReturnStudy: string;
  readonly measurementsGridButtonLabelReturnStudyConfirm: string;
  readonly measurementsGridButtonTitleDownloadReport: string;
  readonly measurementsGridButtonTitleDownloadSecondaryReport: string;
  readonly measurementsGridButtonTitleUploadMeasurement: string;
  readonly measurementsGridButtonLabelStudyDetailsShow: string;
  readonly measurementsGridButtonLabelStudyDetailsHide: string;
  readonly measurementsGridButtonLabelRejectStudy: string;
  readonly measurementsGridButtonLabelRejectStudyInProgress: string;

  readonly measurementsGridButtonTitlePrintOrder: string;
  readonly measurementsGridColumnLabelDeviceSerialNumber: string;

  readonly measurementsGridTitleNewReport: string;

  readonly closeEcgAnalysisFailed: string;
  readonly rejectStudyFailed: string;

  readonly reportUploadButtonConfirmLabel: string;
  readonly reportUploadButtonCancelLabel: string;
  readonly reportUploadButtonConfirmInProgressLabel: string;
  readonly reportUploadMeasurementStatusInvalid: string;
  readonly reportUploadMeasurementFileNameInvalid: string;
  readonly reportUploadFailed: string;

  readonly itemInfoTextLabelHospitalName: string;
  readonly itemInfoTextLabelBmi: string;
  readonly itemInfoTextLabelEssScore: string;

  readonly uploadFormValidationErrorDefault: string;

  readonly uploadFormButtonLabel: string;
  readonly uploadFormButtonInProgressLabel: string;

  readonly uploadSucceeded: string;
  readonly uploadFailed: string;
  readonly uploadFailedCodeNotUnique: string;
  readonly uploadFailedFileNameNotUnique: string;
  readonly uploadErrorCodeNotUnique: string;
  readonly uploadErrorCannotGetLanguageSettings: string;

  readonly uploadFormInvalidLabelRequired: string;

  readonly uploadFormOptionNo: string;
  readonly uploadFormOptionYes: string;
  readonly uploadFormOptionLittle: string;
  readonly uploadFormOptionLot: string;
  readonly uploadFormOptionSometimes: string;
  readonly uploadFormOptionDaily: string;
  readonly uploadFormOptionWell: string;
  readonly uploadFormOptionPoorly: string;
  readonly uploadFormOptionNotAtAll: string;

  readonly uploadFormInfoLabelHours: string;
  readonly uploadFormInfoLabelMinutes: string;
  readonly uploadFormInfoLabelTimes: string;

  readonly uploadFormLabelCode: string;

  readonly uploadFormLabelReportLanguage: string;

  readonly uploadFormInvalidLabelCode: string;

  readonly uploadFormTypeFieldLabel: string;
  readonly enumMeasurementTypeSleep: string;
  readonly enumMeasurementTypeHolterEcg: string;
  readonly enumMeasurementTypeSymptomHolter: string;
  readonly enumMeasurementTypeEcgAtrialFibrillation: string;
  readonly enumMeasurementTypeBloodPressure: string;

  readonly holterTimedTypeOfDurationDays1: string;
  readonly holterTimedTypeOfDurationDays2: string;
  readonly holterTimedTypeOfDurationDays3: string;
  readonly holterTimedTypeOfDurationDays4: string;
  readonly holterTimedTypeOfDurationDays5: string;
  readonly holterTimedTypeOfDurationDays6: string;
  readonly holterTimedTypeOfDurationDays7: string;

  readonly uploadFormMeasurementFileLabel: string;
  readonly uploadFormInvalidLabelFile: string;

  readonly sleepMeasurementFileInvalid: string;
  readonly ecgMeasurementFileInvalid: string;
  readonly bpMeasurementFileInvalid: string;

  readonly uploadFileFailed: string;
  readonly uploadFileButtonCancel: string;
  readonly uploadFileButtonDelete: string;
  readonly uploadFileButtonRetry: string;

  readonly uploadFormLabelGender: string;
  readonly uploadFormLabelGenderF: string;
  readonly uploadFormLabelGenderM: string;
  readonly uploadFormInvalidLabelGender: string;

  readonly uploadFormLabelAge: string;
  readonly uploadFormInvalidLabelAge: string;
  readonly uploadFormInfoLabelAge: string;

  readonly uploadFormLabelHeight: string;
  readonly uploadFormInvalidLabelHeight: string;
  readonly uploadFormInfoLabelHeight: string;

  readonly uploadFormLabelWeight: string;
  readonly uploadFormInvalidLabelWeight: string;
  readonly uploadFormInfoLabelWeight: string;

  readonly uploadFormLabelWaistline: string;
  readonly uploadFormInvalidLabelWaistline: string;
  readonly uploadFormInfoLabelWaistline: string;

  readonly uploadFormLabelCholesterol: string;
  readonly uploadFormInvalidLabelCholesterol: string;
  readonly uploadFormInfoLabelCholesterol: string;

  readonly uploadFormLabelOverweight: string;

  readonly uploadFormLabelProfession: string;
  readonly uploadFormInvalidLabelProfession: string;

  readonly uploadFormLabelHypertension: string;
  readonly uploadFormLabelDiabetes: string;
  readonly uploadFormLabelOverbite: string;
  readonly uploadFormLabelCloggedNasalPassage: string;
  readonly uploadFormLabelAllergicRhinitis: string;
  readonly uploadFormLabelSmallChin: string;
  readonly uploadFormLabelCoronaryArteryDisease: string;
  readonly uploadFormLabelCoronaryInsufficiency: string;
  readonly uploadFormLabelCerebrovascularDisorders: string;
  readonly uploadFormLabelKidneyDisease: string;

  readonly uploadFormLabelSmoking: string;
  readonly uploadFormLabelUseOfSleepingPills: string;
  readonly uploadFormLabelAlcoholConsumption: string;

  readonly alcoholConsumptionOptionOver20: string;

  readonly uploadFormHeaderFrequency: string;
  readonly uploadFormLabelMorningHeadaches: string;
  readonly uploadFormLabelBreathingLoss: string;
  readonly uploadFormLabelFluttering: string;
  readonly uploadFormLabelSnoring: string;
  readonly uploadFormLabelRestlessSleep: string;
  readonly uploadFormLabelDaytimeSleepiness: string;
  readonly uploadFormLabelDryMouthInMorning: string;
  readonly uploadFormLabelNeedToUrinate: string;

  readonly uploadFormOptionFrequencyDaily: string;
  readonly uploadFormOptionFrequencyWeekly: string;
  readonly uploadFormOptionFrequencyMonthly: string;
  readonly uploadFormOptionFrequencyRarely: string;

  readonly uploadFormHeaderProbability: string;
  readonly uploadFormLabelSittingReading: string;
  readonly uploadFormLabelWatchingTelevision: string;
  readonly uploadFormLabelSittingPassive: string;
  readonly uploadFormLabelTravelInCarForAnHour: string;
  readonly uploadFormLabelLyingDownInAfternoon: string;
  readonly uploadFormLabelSittingTalkingToSomeone: string;
  readonly uploadFormLabelSitInPeaceAfterNonAlcoholicLunch: string;
  readonly uploadFormLabelSitInCarAfterItHasStoppedForFewMinutes: string;

  readonly uploadFormOptionProbabilityZero: string;
  readonly uploadFormOptionProbabilitySmall: string;
  readonly uploadFormOptionProbabilityModerate: string;
  readonly uploadFormOptionProbabilityLarge: string;

  readonly uploadFormLabelDiagnosedHeartDisease: string;

  readonly uploadFormLabelCurrentMedication: string;
  readonly uploadFormLabelReasonForStudy: string;

  readonly uploadFormHeaderSleepRegistrationEvents: string;

  readonly uploadFormLabelSleepQuality: string;

  readonly uploadFormOptionSleepQualityBetter: string;
  readonly uploadFormOptionSleepQualitySame: string;
  readonly uploadFormOptionSleepQualityWorse: string;

  readonly uploadFormLabelFallAsleepDuration: string;

  readonly uploadFormOptionFallingAsleepDurationShorter: string;
  readonly uploadFormOptionFallingAsleepDurationSame: string;
  readonly uploadFormOptionFallingAsleepDurationLonger: string;

  readonly uploadFormLabelSleepHours: string;
  readonly uploadFormLabelMinutesAwakeAfterMeasurementStart: string;
  readonly uploadFormLabelAwakeningsDuringMeasurement: string;

  readonly uploadFormLabelSleepMeasurementNight: string;

  readonly uploadFormLabelGoToBedTime: string;
  readonly uploadFormLabelWakeUpTime: string;
  readonly uploadFormInvalidLabelGoToBedWakeUp: string;

  readonly uploadFormLabelParentsHeartAttack60: string;
  readonly uploadFormLabelParentsStroke75: string;

  readonly uploadFormLabelSleepDiary: string;
  readonly uploadFormLabelPatientFeedback: string;
  readonly uploadFormLabelNurseComments: string;

  readonly uploadFormLabelDiary: string;
  readonly uploadFormLabelAbnormalHeartbeats: string;
  readonly uploadFormLabelSlowPulse: string;
  readonly uploadFormLabelFastPulse: string;
  readonly uploadFormLabelPalpitation: string;
  readonly uploadFormLabelIrregularPulse: string;
  readonly uploadFormLabelDizziness: string;
  readonly uploadFormLabelLossOfConsciousness: string;
  readonly uploadFormLabelBlackouts: string;
  readonly uploadFormLabelChestPain: string;
  readonly uploadFormLabelShortnessOfBreath: string;

  readonly uploadFormLabelSymptom: string;
  readonly uploadFormLabelSymptoms: string;
  readonly uploadFormInvalidLabelEcgSymptoms: string;

  readonly uploadFormLabelMeasurementStartTime: string;

  readonly measurementStatusUploaded: string;
  readonly measurementStatusReadyForAnalysis: string;
  readonly measurementStatusReadyForPostAnalysis: string;
  readonly measurementStatusDone: string;
  readonly measurementStatusRejectedByDataOfficer: string;
  readonly measurementStatusPreAnalysed: string;
  readonly measurementStatusInitiated: string;

  readonly nurseMeasurementStatusProcessing: string;
  readonly nurseMeasurementStatusRejected: string;
  readonly nurseMeasurementStatusDone: string;

  readonly newUsersLabelHospitalName: string;
  readonly newUsersLabelEmailAddresses: string;
  readonly newUsersInvalidLabelEmailAddresses: string;
  readonly newUsersInvalidLabelEmailAddressesLength: string;

  readonly newUsersPlaceholderEmailAddresses: string;
  readonly newUsersAddButtonLabel: string;
  readonly newUsersAddButtonLoadingLabel: string;
  readonly newUsersQuestionConfirmSubmit: string;
  readonly newUsersAddFailedInvalidEmails: string;
  readonly newUsersAddFailedExistingEmails: string;
  readonly newUsersAddFailedUnsentEmails: string;
  readonly newUsersAddNursesFailedPost: string;
  readonly newUsersAddNursesSuccessfulPost: string;

  readonly analysisMessageLoadBpDataFailed: string;
  readonly analysisMessageFailedGet: string;
  readonly analysisButtonSave: string;
  readonly analysisLabelSaveInProgress: string;
  readonly analysisMessageSaveFailed: string;
  readonly analysisMessageSaveSuccess: string;
  readonly analysisLabelStartTime: string;
  readonly analysisLabelEcgSamplesDocument: string;
  readonly analysisButtonPreviewReport: string;
  readonly finishAnalysisFailed: string;
  readonly analysisLabelPatientStatusComment: string;
  readonly analysisLabelFindings: string;
  readonly analysisLabelConclusion: string;
  readonly analysisLabelDiagnosis: string;
  readonly analysisLabelButtonFinish: string;
  readonly analysisLabelButtonFinishInProgress: string;
  readonly analysisLabelButtonFinishConfirm: string;
  readonly analysisLabelButtonFinishCancel: string;
  readonly analysisFormValidationError: string;
  readonly analysisFormInvalidLabelRequired: string;
  readonly analysisFormInvalidEcgFile: string;
  readonly analysisLabelExternalAnalysisUrl: string;

  readonly analysisBpChartLegendDiastolic: string;
  readonly analysisBpChartLegendSystolic: string;
  readonly analysisBpChartLegendHeartRate: string;

  readonly bpFindingsActiveTime: string;
  readonly bpFindingsSleepTime: string;
  readonly bpFindingsWholeDay: string;
  readonly bpFindingsAverageSystolicBP: string;
  readonly bpFindingsAverageDiastolicBP: string;
  readonly bpFindingsAverageSystolicRange: string;
  readonly bpFindingsAverageDiastolicRange: string;
  readonly bpFindingsSystolicBPload: string;
  readonly bpFindingsDiastolicBPload: string;
  readonly bpFindingsAveragePuls: string;

  readonly bpSmallerThanActive: string;

  readonly reportLabelPatient: string;
  readonly reportPatientDataPageTitle: string;
  readonly reportPageLabel: string;
  readonly reportUniqueIdLabel: string;

  readonly reportFindingsSectionTitle: string;

  readonly bpReportBPmonitoring24h: string;

  readonly ecgReportSymptomHolterDisclaimer: string;
  readonly ecgReportSymptomFindingsTitle: string;
  readonly ecgReportAtrialFibrillationTitle: string;
  readonly ecgReportAtrialFibrillationDisclaimer: string;
  readonly ecgReportLabelSymptom: string;

  readonly downloadedReportFilenamePrefix_sleep: string;
  readonly downloadedReportFilenamePrefix_ecgHolter: string;
  readonly downloadedReportFilenamePrefix_symptomHolter: string;
  readonly downloadedReportFilenamePrefix_ecgAtrialFibrillation: string;
  readonly downloadedReportFilenamePrefix_samples: string;
  readonly downloadedReportFilenamePrefix_bloodPressure: string;

  readonly ecgDataOfficerFileshareFolderInvalid: string;
  readonly assignDoctorFailed: string;

  readonly errors_default: string;
  readonly errors_downloadReportFailed: string;

  readonly assignDoctorSelectorLabelCurrent: string;

  readonly timeFilterFromLabel: string;
  readonly timeFilterToLabel: string;
  readonly timeFilterSearchButton: string;
  readonly timeFilterErrorMessage: string;
  readonly timeFilterLoadingLabel: string;

  readonly measurementListCount: string;
  readonly measurementListAllColumnFilterOption: string;
  readonly invalidDeviceSerialNumber: string;

  readonly changeLanguageLabel: string;
  readonly englishLang: string;
  readonly finnishLang: string;
  readonly displayLanguageLabel: string;
  readonly changeLanguageFailedMessage: string;

  readonly initiateMeasurementReasonForStudyInvalid: string;
  readonly initiateFormButtonLabel: string;
  readonly initiateFormButtonInProgressLabel: string;
  readonly initiateFailed: string;

  readonly reasonForStudyEmpty: string;
  readonly initiatedMeasurementFailedGet: string;
  readonly invalidHospitalNameLength: string;
  readonly orderFormLabelOperatorHospital: string;
  readonly orderFormLabelOperatorHospitalInvalid: string;
  readonly navigationOrderMeasurement: string;

  readonly orderFormButtonLabel: string;
  readonly orderFormButtonInProgressLabel: string;
  readonly orderFailed: string;
  readonly operatorHospitalsFailedGet: string;

  readonly printOrderFormLabelSsn: string;
  readonly printOrderFormInvalidLabelSsn: string;
  readonly printOrderFormLabelName: string;
  readonly printOrderFormInvalidLabelName: string;
  readonly printOrderFormButtonLabel: string;
  readonly printOrderFormButtonInProgressLabel: string;
  readonly printOrderFormValidationErrorDefault: string;
  readonly printOrderFormLabelCode: string;
  readonly printOrderFormLabelOrderingHospital: string;
  readonly printOrderFormLabelOperatorHospital: string;
  readonly printOrderFormLabelUniqueId: string;
  readonly printOrderFormLabelInitiatedTime: string;
  readonly printOrderFormErrorGettingData: string;
  readonly printOrderFormType: string;

  readonly orderingNurseCheckboxLabel: string;

  readonly hospitalsTitle: string;
  readonly hospitalsFailedToGet: string;

  readonly navigationHospitals: string;

  readonly editHospitalFailedToLoadHospitalDetails: string;
  readonly editHospitalFailedToAddOperator: string;
  readonly editHospitalOperatorHospitals: string;
  readonly editHospitalChooseOperatorText: string;

  readonly measurementsGridButtonTitlePrintForm: string;

  readonly printOrderFormInvalidLabelType: string;
  readonly printOrderFormInvalidLabelCode: string;
  readonly printOrderFormInvalidLabelUniqueId: string;
  readonly printOrderFormInvalidLabelInitiatedTime: string;
  readonly printFormLabelLanguage: string;
  readonly printFormInvalidLabelLanguage: string;
  readonly printFormButtonLabel: string;

  readonly swedishLang: string;
  readonly russianLang: string;

  readonly printFormPatientTemplateNotFound: string;
  readonly printFormFetchError: string;

  readonly printOrderFormNoOperatorInformation: string;
  readonly measurementsGridTitleJobPending: string;
  readonly measurementsGridTitleJobFailed: string;
  readonly measurementsGridTitleJobSucceeded: string;

  readonly eFormLandingPageTitle: string;
  readonly eFormCodeInputLabel: string;
  readonly eFormCodeInputErrorLabel: string;
  readonly eFormLandingPageError: string;
  readonly eFormGetFormError: string;
  readonly eFormUpdateFormError: string;

  readonly eFormHeaderTitle: string;
  readonly eFormHeaderText_paragraph1: string;
  readonly eFormHeaderText_paragraph2: string;
  readonly eFormFooterTitle: string;
  readonly eFormFooterText_paragraph1: string;

  readonly initiatedMeasurementEFormLanguageNotSaved: string;

  readonly eFormSubmitButton: string;

  readonly textTooLongError: string;

  readonly editHospitalFailedToUpdateHospitalMeasurementTypes: string;
  readonly editHospitalAllowedTypes: string;
  readonly editHospitalAllowedTypesText: string;
  readonly editHospitalSubmitAllowedTypes: string;

  readonly errorCannotGetHospitalTypes: string;
  readonly editHospitalSuccessfullyUpdatedMeasurementTypes: string;

  readonly measurementsGridButtonLabelDeleteMeasurement: string;
  readonly measurementsGridButtonLabelDeleteMeasurementTooltip: string;
  readonly measurementsGridButtonLabelDeleteMeasurementInProgress: string;

  readonly deletingMeasurementFailed: string;

  readonly measurementsGridButtonLabelShowComments: string;
  readonly modalResponseClose: string;

  readonly commentFetchingFailed: string;
  readonly commentAddingFailed: string;

  readonly hospitalNurseSettingsTitle: string;
  readonly hospitalNurseSettings2FaCheckbox: string;
  readonly hospitalNurseSettingsUpdateButton: string;
  readonly hospitalNurseSettingsUpdateSuccess: string;
  readonly hospitalNurseSettingsUpdateError: string;

  readonly errorCannotGetSummary: string;

  readonly labelHospitalName: string;

  readonly hospitalInformationUpdateError: string;
  readonly hospitalInformationUpdateSuccess: string;
  readonly hospitalInformationUpdateButton: string;
  readonly hospitalInformationPublicName: string;
  readonly hospitalInformationPublicNameError: string;
  readonly hospitalInformationTitle: string;
  readonly hospitalInformationOperatorAppointmentDescription: string;
  readonly hospitalInformationOperatorAppointmentDescriptionError: string;
  readonly hospitalInformationAddress: string;
  readonly hospitalInformationAddressError: string;
  readonly hospitalInformationPostalCode: string;
  readonly hospitalInformationPostalCodeError: string;
  readonly hospitalInformationCity: string;
  readonly hospitalInformationCityError: string;

  readonly hospitalPatientFormTemplatesTitle: string;
  readonly hospitalPatientFormTemplatesButton: string;
  readonly hospitalPatientFormTemplatesError: string;
  readonly hospitalPatientFormTemplatesSuccess: string;

  readonly editMeasurementDataButton: string;
  readonly editMeasurementDataUpdateButtonLabel: string;
  readonly editMeasurementDataUpdateInProgressLabel: string;
  readonly editMeasurementDataFormInvalid: string;
  readonly editMeasurementDataUpdateFailureMessage: string;
  readonly editMeasurementDataUpdateSuccessMessage: string;

  readonly measurementGridAssignTechnicianSuccessful: string;
  readonly measurementGridAssignTechnicianError: string;
  readonly measurementsGridAssignTechnicianButton: string;
  readonly measurementsGridAssignTechnicianInProgress: string;
  readonly measurementsGridColumnLabelTechnician: string;
  readonly measurementsGridButtonLabelAssignDoctor: string;

  readonly characterCounterCharactersRemaining: string;
  readonly eformSymptomWarningLabel: string;

  readonly navigationLabelOldServiceModel: string;

  readonly hospitalLanguageSettingsTitle: string;
  readonly hospitalReportLanguageToggleCheckbox: string;
  readonly hospitalLanguageSelect: string;
  readonly hospitalLanguageSettingsUpdateButton: string;
  readonly hospitalLanguageSettingsUpdateSuccess: string;
  readonly hospitalLanguageSettingsUpdateFailure: string;

  readonly uploadGetContextFailed: string;

  readonly measurementGridReportLanguage: string;

  readonly analysisPatientStatusCommentsGeneratorDoneButton: string;

  readonly analysisPatientStatusCommentsRegenerateButton: string;
  readonly analysisPatientStatusCommentsConfirmRegenerateButton: string;
  readonly analysisPatientStatusCommentsCancelRegenerateButton: string;

  readonly analysisRegenerationWarningLabel: string;
  readonly generator_patientStatusComments_reasonForStudy_arrhythmiaSensations: string;
  readonly generator_patientStatusComments_reasonForStudy_other: string;
  readonly generator_patientStatusComments_rhythmAffectingMedication_no: string;
  readonly generator_patientStatusComments_rhythmAffectingMedication_yes: string;
  readonly generator_patientStatusComments_symptomsDuringRecording_no: string;
  readonly generator_patientStatusComments_symptomsDuringRecording_yes: string;
  readonly generator_patientStatusComments_symptomsDuringRecording_noDiary: string;

  readonly generator_label_patientStatusComments_reasonForStudy: string;
  readonly generator_label_patientStatusComments_rhythmAffectingMedication: string;
  readonly generator_label_patientStatusComments_symptomsDuringRecording: string;

  readonly generator_label_findings_prevailingRhythm: string;
  readonly generator_label_findings_averageHr: string;
  readonly generator_label_findings_minimumHr: string;
  readonly generator_label_findings_maximumHr: string;
  readonly generator_label_findings_pausesOver2_5s: string;
  readonly generator_label_findings_ventricularBeats: string;
  readonly generator_label_findings_commentVentricular: string;
  readonly generator_label_findings_atrialBeats: string;
  readonly generator_label_findings_atrialTachycardias: string;
  readonly generator_label_findings_commentAtrial: string;
  readonly generator_label_findings_avConduction: string;
  readonly generator_label_findings_deltaWave: string;
  readonly generator_label_findings_qrsWidth: string;
  readonly generator_label_findings_qtTime: string;
  readonly generator_label_findings_stSegment: string;
  readonly generator_label_findings_symptomDiaryComment: string;

  readonly generator_button_findings_getExternalAnalysis: string;

  readonly generator_findings_option_normal: string;
  readonly generator_findings_option_other: string;

  readonly uploadFileFailedFileAlreadyUploaded: string;

  readonly reportUploadFileNotForThisMeasurement: string;

  readonly analysisDataOutdatedErrorLabel: string;

  readonly generator_label_findings_ventricularTachycardias: string;
  readonly externalFindingsMissingFieldsWarningLabel: string;

  readonly dataOfficerMeasurementStatusDeleted: string;
  readonly measurementsGridButtonLabelRestoreMeasurement: string;
  readonly measurementsGridButtonLabelRestoreMeasurementInProgress: string;
  readonly restoreMeasurementFailed: string;
  readonly restoreMeasurementSucceeded: string;
  readonly confirmDeleteMeasurement: string;
  readonly editHolterTypeButton: string;
  readonly setHolterTypeButton: string;

  readonly measurementsGridButtonLabelReassignDoctor: string;
  readonly reassignMeasurementSucceeded: string;
  readonly reassignMeasurementFailed: string;
  readonly assignFailedDoctorDoesNotHaveAccessToExternalAnalysis: string;
  readonly confirmReassignDoctor: string;

  readonly hospitalPutHospitalInChainButton: string;
  readonly hospitalPutHospitalInChainTitle: string;
  readonly hospitalPutHospitalInChainSuccess: string;
  readonly hospitalPutHospitalInChainFailure: string;
  readonly hospitalPutHospitalInChainSelect: string;

  readonly eFormLandingPageTitle_304: string;
  readonly eFormLandingPageGreeting: string;
  readonly eFormLandingPageDescription: string;
  readonly eFormLandingPageThankYou: string;

  readonly measurementDetailsFileNameLabel: string;
  readonly measurementDetailsDataSizeLabel: string;

  readonly homeHolterLandingPageTitle: string;
  readonly homeHolterLandingPageDescription: string;
  readonly homeHolterLandingPageCustomerService: string;

  readonly hospitalNurseSettingsCombinedReportCheckbox: string;

  readonly externalAnalysisUrlLabel: string;

  readonly measurementTypeLabelHomeHolter: string;
  readonly measurementTypeLabelHomeHolter24h: string;
  readonly measurementTypeLabelHomeHolter48h: string;
  readonly measurementTypeLabelHomeHolter7d: string;

  readonly measurementDetailsSpecifiedHomeHolterDurationLabel: string;

  readonly jobAlreadyExistsMessage: string;

  readonly editHospitalAllowedTypesHeaderNonHome: string;
  readonly editHospitalAllowedTypesHeaderHome: string;

  readonly homeHolterDuration1dTypeLabel: string;
  readonly homeHolterDuration2dTypeLabel: string;
  readonly homeHolterDuration3dTypeLabel: string;
  readonly homeHolterDuration4dTypeLabel: string;
  readonly homeHolterDuration5dTypeLabel: string;
  readonly homeHolterDuration6dTypeLabel: string;
  readonly homeHolterDuration7dTypeLabel: string;
  readonly homeHolterTypeLabel: string;

  readonly homeSleepTypeLabel: string;
  readonly homeSymptomHolterTypeLabel: string;
  readonly homeEcgAtrialFibrillationTypeLabel: string;
  readonly homeBloodPressureTypeLabel: string;

  readonly finishAnalysisFailedBecauseSamplesDocumentMissing: string;

  readonly mailingInfoFormNameLabel: string;
  readonly mailingInfoFormNameInvalidLabel: string;
  readonly mailingInfoFormMailingAddressLabel: string;
  readonly mailingInfoFormMailingAddressInvalidLabel: string;
  readonly mailingInfoFormEmailAddressLabel: string;
  readonly mailingInfoFormPhoneNumberLabel: string;
  readonly mailingInfoFormPhoneNumberInvalidLabel: string;
  readonly mailingInfoFormPermissionLabel: string;
  readonly mailingInfoFormOrderButtonLabel: string;
  readonly mailingInfoFormDeviceOrderThankYouMessage: string;
  readonly mailingInfoFormDeviceOrderFailedErrorMessage: string;

  readonly eFormWaitForDeviceMessage: string;

  readonly dataOfficerFolderHasNotFinishedSyncing: string;
}

export function localizationKey(key: keyof LocalizationStrings): string {
  return String(key);
}

export enum LanguageCode {
  EN_US = "en-us",
  FI_FI = "fi-fi",
  SV_SV = "sv-sv"
}
